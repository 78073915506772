<template>
    <div class="home" v-cloak>
        <div class="home-top">
            <div class="top-one">
                <p class="p-word">{{$t('login.txt')}}!</p>
                <div class="p-user" >
                  <img v-if="isLogin" @click="gotomyInfo('mine-info')" :src="require('../../assets/images/my.png')"   alt="">
                  <div v-else class="login-one">
                        <div class="change" @click="showPopup">
                            <p class="qi-box">
                                <svg class="guoqi" aria-hidden="true">
                                    <use :xlink:href="guoqiclass"></use>
                                </svg>
                                <span>{{lastLang | langtext }}</span>
                            </p>
                            <van-icon name="arrow-down" class="vicon"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="top-nav">
                <div class="top-nav-item" @click="gotoauction('1')">
                    <img src="../../assets/imgs/cb.png" alt="">
                    <p>Cloud Bid</p>
                </div>
                <div class="top-nav-item"  @click="gotoauction('2')">
                  <img class="tt-img" src="../../assets/imgs/tt.png" alt="">
                  <p>Time Twister</p>
                </div>
                <div class="top-nav-item"  @click="gotoauction('1')">
                   <img src="../../assets/imgs/hot.png" alt="">
                    <p>{{$t('home.hot')}}{{lang!=="cn"?' ':""}}{{$t('common.auction1')}}</p>
                </div>
                <div class="top-nav-item" @click="gotowhere('home-market')">
                   <img src="../../assets/imgs/house.png" alt="">
                   <p :class="lang=='cn'?'':'p-four'">{{$t('home.sheng_market')}}</p>
                </div>
            </div>
        </div>
        <div class="home-content" >
            <div class="home-content-box">
                <div class="content-one">
                    <van-swipe
                        vertical
                        class="notice-swipe"
                        :autoplay="3000"
                        :show-indicators="false"
                    >
                        <van-swipe-item class="clearfix"  v-for="(l,i) in list" :key="i">
                            <div class="left ">
                                <img src="../../assets/imgs/laba.png" class="nav-icon" alt="">
                                <img class="left-img" v-lazy="l.winer_thumb" alt="">
                                <span class="left-name color-blue">{{l.winner_id}}</span>
                                <p class="left-title van-ellipsis">{{$t('add.get')}} {{l.title}}</p>
                            </div>
                            <!-- <div class="right" >{{l.winningTime || l.winning_time}}</div> -->
                            <!-- <div > 
                                <p class="right" >{{l.winningTime || l.winning_time}}</p>
                            </div> -->
                        </van-swipe-item>
                    </van-swipe>
                    
                </div>
                <div class="home-main">
                    <div class="main-one">
                        <div class="one-nav">
                            <div class="nav-left">
                                <!-- <van-icon name="fire" class="nav-icon" ></van-icon> -->
                                <img src="../../assets/imgs/gonggao.png" class="nav-icon" alt="">
                                <van-notice-bar
                                    class="nav-notice"
                                    scrollable
                                    color="#666"
                                    background="#fff"
                                    v-if="gongGaoList[0]"
                                    :text="gongGaoList[0].title"
                                />
                            </div>
                            <div class="nav-right" @click="gotowhere('home-gonggao')">
                                <span class="more-text">{{$t('common.more')}}</span>
                                <van-icon name="arrow" class="more-icon" />
                            </div>
                        </div>
                        <div class="one-banner">
                            <MySwipe class="banner" id="bone" :swiperoptions="swiperoptions"  >
                                <MySwipeItem v-for="(l,i) in bannerList" :key="i">
                                     <img class="b-img" v-lazy="l" alt="">   
                                     <p class="banner-count" >{{i+1}} / {{bannerList.length}} </p>
                                </MySwipeItem>
                            </MySwipe>
                        </div>
                    </div>
                    <div class="main-two">
                        <div class="one-nav">
                            <div class="nav-left">
                                <p class="left-text">{{$t('home.hot')}}{{lang!=="cn"?' ':""}}{{$t('common.auction1')}}</p>
                            </div>
                            <div class="nav-right" @click="gotoauction('1')">
                                <span class="more-text">{{$t('common.more')}}</span>
                                <van-icon name="arrow" class="more-icon" />
                            </div>
                        </div>
                        <div class="two-content">
                            <MyList classobj="hot-list"  :listadd="listadd" ref="mylist" :refreshajax="refresh" >
                                <div class="two-item" :class="i%2==0?'item-odd':'item-even'" v-for="(l,i) in hotlist" v-if="l.is_try!==1" :key="i" @click="gotogoodauction(l)">
                                    <img class="item-img" v-lazy="l.thumb" alt="">
                                    <div class="item-title">
                                        <p>{{l.qishu_trans}}</p>
                                        <p v-if="l.active_id==1"> ${{(l.total_amount?l.total_amount:'0') | number_format}}</p>
                                    </div>
                                    <p v-if="l.active_id==2" class="item-price">{{$t('home.now_price')}} ${{(l.now_price?l.now_price:'0') | number_format}}</p>
                                    <div class="item-name van-multi-ellipsis--l2" :class="l.active_id==1?'item-name-1':'item-name-2'" >
                                        {{l.title}} 
                                    </div>
                                    
                                    <div class="item-time">
                                        <div class="item-last " v-if="l.active_id==2">
                                            <template v-if="l.timeCount>0&&!l.timeend">
                                                <img class="item-lou" :src="require('@/assets/gif/lou.gif')" alt="">
                                                <van-count-down @finish="timeEndAction(l)" millisecond  format="HH:mm:ss:SS" :time="l.timeCount" class="item-end" >
                                                </van-count-down>
                                            </template>
                                            <div class="item-wait" v-else>
                                                <p class="p-end" v-if="!l.resultOut&&l.is_end!='1'" ><van-loading class="p-icon" color="#DF561C" /> {{$t('add.wait_for_result')}}</p>
                                                <p class="p-result" v-else>{{$t('add.ended')}}</p>
                                                <!-- v-if="l.resultOut" -->
                                            </div>
                                        </div>
                                        <div class="item-right" v-if="l.active_id==1">
                                            <!-- v-if="!l.resultOut" -->
                                            <div class="item-right-add" >
                                                <div class="item-right-process">
                                                    <van-progress v-if="l.total_need" :percentage="parseInt((l.join_num/l.total_need)*100)" color="#DF561C" :show-pivot="false"/>
                                                </div>
                                                <div class="item-right-total">
                                                <p>{{$t('home.has_enter')}}</p>
                                                <p>
                                                    <span class="color-main">{{l.join_num}}</span>
                                                    <span>/</span>
                                                    <span>{{l.total_need}}</span>
                                                </p>
                                                </div>
                                            </div>
                                            <!-- <div class="item-wait" v-else>
                                                <p class="p-result" >{{$t('add.ended')}}</p>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </MyList>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-popup class="login-popup" v-model="show" position="bottom"  :style="{ height: '5.3333rem' }" duration="0.2" >
            <div class="login-popup-box">
                <div class="pop-top">
                    <p>{{$t('login.set_lang')}}</p>
                    <van-icon name="cross" class="pop-close" @click="show=false" />
                </div>
                <div class="pop-btns">
                    <van-button class="btn-normal" @click="changeLang('cn')" :class="lang=='cn'?'btn-active':''" >中文</van-button>
                    <van-button class="btn-normal"  @click="changeLang('th')" :class="lang=='th'?'btn-active':''">ไทย</van-button>
                    <van-button class="btn-normal"  @click="changeLang('en')" :class="lang=='en'?'btn-active':''">English</van-button>
                </div>
                <van-button class="sure-btn" @click="sureChange">{{$t('login.sure')}}</van-button>
            </div>
        </van-popup>

        <!-- 视频弹框  视频播放结束 弹框消失  -->
        <van-overlay class="my-overlay video-overlay" :show="vshow"  >
            <div class="video-mask" @click.stop>
                 <div class="wrapper-video">
                     <video  
                    muted
                    preload  
                    autoplay 
                    x5-video-player-fullscreen="true" 
                    x5-playsinline
                    playsinline
                    webkit-playsinline
                    x5-video-player-type="h5" 
                    id="myVideo" 
                    class="home-video wrapper-video" 
                    src="https://onebid.club/img/welcome.mp4"></video>
                 </div>
                <img @click="changevshow"  class="close-btn" :src="require('@/assets/images/closeicon.png')" alt="">
            </div>
        </van-overlay>
    </div>
</template>


<script>
import {mapState,mapMutations} from "vuex"
// import {myinfo} from "@/common"
import {vantLocales} from "@/main.js"
export default {
    // mixins:[myinfo],
    name:'main-homeIndex',
    data(){
        return {
            lang:localStorage.getItem("langData") || 'th',
            lastLang:localStorage.getItem("langData") || 'th',
            banner:[],
            hotlist:[],
            list:[],
            firstGao:"",
            vshow:false,
            show:false,
            page:1,
            pageSize:10,
            total:0,
            // annoList:[],
            // bannerList:[],
            setCardList:[],
            swiperoptions:{
                observer:true,
                autoplay:{
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,  
                },
                resistanceRatio:0,
                spaceBetween : 15,
                slidesPerView :1.2,
                 on:{
                    slideChange: function(){
                    },
                },
            },
            isLogin:localStorage.getItem("access_token")?true:false
        }
    },
    watch:{ 
        homewinnerList(v){
            // console.log(v);
            // 把第一条数据 删除 更新
            this.list.shift(0)
            var obj = v[v.length-1]
            var data = {};
            if(obj['tt_winninginfo']){
                data = obj['tt_winninginfo']
            }else{
                data = obj['cb_winninginfo']
            }       
            this.list.push({
                winer_thumb:data.headimg,
                winner_id:data.uid,
                title:data.winnerInfo.title,
                winningTime:data.winnerInfo.winningTime
            })

            this.hotlist = this.hotlist.map(v=>{
                if(v.id==data.good_id&&v.qishu==data.qishu){
                    v = {...v,resultOut:true}
                }
                return v;
            })
        },
        jiqigoodList(v){
            var new_data = v[0];
            // 及时更新  ws 数据
            this.hotlist = this.hotlist.map(item=>{
                if(item.active_id==1){
                    // cb
                    if(new_data['buy_cb']){
                        if(new_data['buy_cb'].qishu==item.qishu&&new_data['buy_cb'].good_id==item.id){
                            // 推送了一条 cb 数据
                            item = {
                                ...item,
                                join_num:item.join_num*1 + new_data['buy_cb'].total*1 
                            }
                        }
                    }
                }
                if(item.active_id==2){
                    // tt 
                    if(new_data['buy_tt']){
                         if(new_data['buy_tt'].qishu==item.qishu&&new_data['buy_tt'].good_id==item.id){
                            // 推送了一条 cb 数据
                            item = {
                                ...item,
                                timeCount:parseInt(new_data['buy_tt'].day) * 24 * 3600 * 1000 + parseInt(new_data['buy_tt'].hours)  * 3600 * 1000 + parseInt(new_data['buy_tt'].mins)  *60 * 1000 + parseInt(new_data['buy_tt'].second)  * 1000
                            }
                        }
                    }
                }
                return item;
            })
        },
    },
    computed:{
        ...mapState(['homewinnerList','jiqigoodList','gongGaoList','bannerList']),
        guoqiclass(){
            if(this.lastLang=="cn"){
                return "#iconzhongguo"
            }else if(this.lastLang=="en"){
                return "#iconyingguo"
            }else{
                return "#icontaiguo"
            }
            return this.lastLang
        },
    },
    methods:{
        changevshow(){
            this.vshow =false;
        },
        sureChange(){
            localStorage.setItem('langData',this.lang);
            this.$i18n.locale = this.lang;
            this.lastLang = this.lang;
            this.show = false;
            vantLocales(this.lang)


             this.$ajax.announcement()
            .then(res=>{
                if(res.code==200){
                    // this.annoList = res.data.anno;
                    this.setgongGaoList(res.data.anno)
                    // this.bannerList = res.data.banner;
                    this.setBannerList(res.data.banner)
                }
            })

             // 首页滚动中奖人信息
            this.$ajax.winningnotify()
            .then(res=>{
                if(res.code==200){
                    this.list = res.data
                }
            })

            this.page = 1;
            // 热门竞猜 
            this.$ajax.hotlist({
                page:this.page,
                pageSize:this.pageSize
            })
            .then(res=>{
                if(res.code==200){
                    this.hotlist = res.data.data.map((item,i)=>{
                        var timeCount  = parseInt(item.day) * 24 * 3600 * 1000 + parseInt(item.hours)  * 3600 * 1000 + parseInt(item.mins)  *60 * 1000 + parseInt(item.second)  * 1000;
                        item = {...item,timeCount}
                        return item
                    })
                    this.total = res.data.total
                }
            })
        },
        changeLang(lang){
            this.lang = lang;
        },
        showPopup(){
            this.lang = localStorage.getItem("langData") || 'th',
            this.show = true;
        },
        ...mapMutations(['setgongGaoList','setBannerList','setdashboard']),
        listadd(callback,cbend){
            if(this.page<Math.ceil(this.total/this.pageSize)){
                this.page++;
                this.$ajax.hotlist({
                    page:this.page,
                    pageSize:this.pageSize
                })
                .then(res=>{
                    if(res.code==200){
                        this.hotlist = this.hotlist.concat(res.data.data.map((item,i)=>{
                            var timeCount  = parseInt(item.day) * 24 * 3600 * 1000 + parseInt(item.hours)  * 3600 * 1000 + parseInt(item.mins)  *60 * 1000 + parseInt(item.second)  * 1000;
                            item = {...item,timeCount}
                            return item
                        }))
                        this.total = res.data.total;
                    }
                    callback()
                })
            }else{
                cbend()
            }
        },
        refresh(callback){
            this.page = 1;
            // 热门竞猜 
            this.$ajax.hotlist({
                page:this.page,
                pageSize:this.pageSize
            })
            .then(res=>{
                if(res.code==200){
                    this.hotlist = res.data.data.map((item,i)=>{
                        var timeCount  = parseInt(item.day) * 24 * 3600 * 1000 + parseInt(item.hours)  * 3600 * 1000 + parseInt(item.mins)  *60 * 1000 + parseInt(item.second)  * 1000;
                        item = {...item,timeCount}
                        return item
                    })
                    this.total = res.data.total
                }
                callback();
            })
        },
        timeEndAction(item){
            this.hotlist = this.hotlist.map(v=>{
                if(v.id==item.id&&v.qishu==item.qishu){
                    v = {...v,timeend:true}
                }
                return v;
            })
        },
        
        gotoauction(active){
            this.$store.commit("changeactive",active);
            // this.$router.push({name:'main-auctionIndex',query:{active:active}})
            this.$router.push({name:'main-auctionIndex'})
        },
        gotomyInfo(name){
            // var access_token = localStorage.getItem("access_token"); 
            // if(access_token){
               
            // }else{
            //     this.$router.push({name:'signin'})
            // }
            this.$router.push({
                name
            })
        },
        gotowhere(name){ 
            this.$router.push({
                name
            })
        },
        gotogoodauction(item){
             this.$router.push({name:'goods',query:{
              gid:item.id,
              qishu:item.qishu,
              type:item.active_id==1?'cb':'tt',
              isend:0,  // 没有结束
          }})
        }
    },
    mounted(){

        // 判断是否是第一次进来
        // localStorage.removeItem('visitcount')
        if(localStorage.visitcount){
            localStorage.visitcount++;
            this.vshow = false;
        }else{
            localStorage.visitcount = 1;  // 第一次进来
            this.vshow = true;
            var video = document.getElementById("myVideo")    
            var that = this;  
            video.addEventListener("loadedmetadata",function(){            
                that.$nextTick(()=>{
                    video.play();
                })
            })   
            video.addEventListener('ended', function(){
                that.vshow = false;
            }); 
        }
        

        // 获取公共列表
        if(!this.bannerList.length>0){
            this.$ajax.announcement()
            .then(res=>{
                if(res.code==200){
                    // this.annoList = res.data.anno;
                    this.setgongGaoList(res.data.anno)
                    // this.bannerList = res.data.banner;
                    this.setBannerList(res.data.banner)
                }
            })
        }
       

        
        // 首页滚动中奖人信息
        this.$ajax.winningnotify()
        .then(res=>{
            if(res.code==200){
                this.list = res.data
            }
        })


        // 热门竞猜 
        this.$ajax.hotlist({
            page:this.page,
            pageSize:this.pageSize
        })
        .then(res=>{
            if(res.code==200){
                this.hotlist = res.data.data.map((item,i)=>{
                    var timeCount  = parseInt(item.day) * 24 * 3600 * 1000 + parseInt(item.hours)  * 3600 * 1000 + parseInt(item.mins)  *60 * 1000 + parseInt(item.second)  * 1000;
                    item = {...item,timeCount}
                    return item
                })
                this.total = res.data.total
            }
        })
    }
}
</script>
